import React, { useContext } from 'react';
import { Link, PageProps } from 'gatsby';
import SEO from '@/components/seo';
import UIBreadcrumb from '@/components/UI/UIBreadcrumb';
import { Context } from '@/components/Layout';

import * as pageHeaderCategory from '@/styles/object/page-header-category.module.scss';

const NotFound: React.FC<PageProps> = () => {
  const loading = useContext(Context);
  return (
    !loading && (
      <>
        <SEO title="お探しのページが見つかりません。" />
        <div className="page-wrap --common">
          <div className="page-wrap__wave" />
          <div className="page-wrap__bg" />
          <header className={pageHeaderCategory.page_header_category}>
            <h1>
              <small
                className={pageHeaderCategory.page_header_category__head_text}
              >
                お探しのページが見つかりませんでした
              </small>
              <div className={pageHeaderCategory.page_header_category__text}>
                Not Found
              </div>
            </h1>
            <p className={pageHeaderCategory.page_header_category__lead}>
              申し訳ございません。お探しのページが見つかりませんでした。
              <br />
              他の場所に移動、もしくは削除された可能性があります。
            </p>
            <p className={pageHeaderCategory.page_header_category__lead}>
              <Link
                to="/"
                className="link-text-01 js-hover-circle"
                data-circle-type="stick"
                data-circle-inner="arrow-right"
              >
                Top Page
              </Link>
            </p>
          </header>
        </div>
        <UIBreadcrumb UpperItem={[]} CurrentItem="404 Not Found" />
      </>
    )
  );
};

export default NotFound;
